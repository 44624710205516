import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from 'react'
import HeaderPage from "./components/header";
import { Row, Col, Container, Card } from "react-bootstrap";
import './App.css'

function App() {
  const [showIframe, setShowIframe] = useState(false);
  const [urlIframe, setUrlIframe] = useState(false);
  const [addClassR, setAddClassR] = useState('');
  const [addClassA, setAddClassA] = useState('');
  const [addClassV, setAddClassV] = useState('');

  const urlReact = 'https://pivotereact.vercel.app/'
  const urlAngular = 'https://dicio-pivote-angular-v16.vercel.app/'
  const urlVanilla = 'https://pivotevanilla.vercel.app/'

  const mostrarIframeA = () => {
    setShowIframe(true);
    setUrlIframe(urlAngular);
    setAddClassA('active-angular')
    setAddClassR('')
    setAddClassV('')
  }

  const mostrarIframeR = () => {
    setShowIframe(true);
    setUrlIframe(urlReact);
    setAddClassA('')
    setAddClassR('active-react')
    setAddClassV('')
  }

  const mostrarIframeV = () => {
    setShowIframe(true);
    setUrlIframe(urlVanilla);
    setAddClassA('')
    setAddClassR('')
    setAddClassV('active-vanilla')
  }

  return (
    <>
      <HeaderPage></HeaderPage>
      <Container>
        <Row className="margin-bottom-50">
          <Col md="12" className="d-flex flex-wrap justify-content-center margin-bottom-50" >
            <h1>Dicio SDK´s</h1>
            <p className="text-center">
              Es una librería diseñada para una fácil integración, que
              permite lanzar un proceso de DICIO, sin tener que pasar por su
              infraestructura, permitiendo escuchar eventos del proceso
              desde el sitio que lanza el flujo.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col md="1">
            <Col md="12" className="d-flex justify-content-center margin-bottom-20">
              <Card className={`${addClassR} card-hover react`} style={{ width: '18rem' }} onClick={(e) => mostrarIframeR()}>
              <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                  <Card.Title className="">React</Card.Title>
                  <Card.Img variant="top" src="../images/react.svg" width={200} />
                </Card.Body>
              </Card>
            </Col>
            <Col md="12" className="d-flex justify-content-center margin-bottom-20">
              <Card className={`${addClassA} card-hover angular`} style={{ width: '18rem' }} onClick={() => mostrarIframeA()} >
                <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                  <Card.Title className="">Angular</Card.Title>
                  <Card.Img variant="top" src="../images/angular.svg" />
                </Card.Body>
              </Card>
            </Col>
            <Col md="12" className="d-flex justify-content-center margin-bottom-20">
              <Card className={`${addClassV} card-hover vanillajs`} style={{ width: '18rem' }} onClick={() => mostrarIframeV()}>
                <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                  <Card.Title className="">Vanilla</Card.Title>
                  <Card.Img variant="top" src="../images/javascript.svg" />
                </Card.Body>
              </Card>
            </Col>
          </Col>

          <Col md="11">
            <Row>
            { (showIframe) ? 
              <Col md="12">
                <iframe
                  src={urlIframe}
                  id="inlineFrameExample"
                  title="Inline Frame Example"
                  width="100%"
                  height="1000px"
                ></iframe>
              </Col>
              :
              <Col md="8"></Col>
            }
          </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
